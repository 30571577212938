import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet';

import Navigation from '../components/Navigation';
import Footer from '../sections/Footer';
import faviconPath from '../images/favicon.ico';
import ExternalLink from '../components/ExternalLink';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0 0 0;
  font-family: Roboto;
  max-height: 100%;
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Heading = styled.div`
  font-weight: bold;
  font-size: 50px;
  line-height: 59px;
  color: rgba(0, 0, 0, 0.870588);
  margin-top: 127px;
  user-select: none;
`;

const LargeExternalLink = styled(ExternalLink)`
  font-size: 42px;
  line-height: 59px;
  margin-top: 127px;
`;

const FooterContainer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
`;

const Help = () => (
  <Container>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Help - Bidmatik</title>
      <link rel="canonical" href="https://bidmatik.com/help" />
      <link rel="shortcut icon" type="image/png" href={faviconPath} />
    </Helmet>
    <Navigation />
    <Center>
      <Heading>
        Here to help
      </Heading>
      <LargeExternalLink href="mailto:help@bidmatik.com?subject=Help%20request">
        help@bidmatik.com
      </LargeExternalLink>
    </Center>
    <FooterContainer>
      <Footer showBackground />
    </FooterContainer>
  </Container>
);

export default Help;
